@font-face {
  font-family: 'Armenian-National-Trail';
  src:  url('fonts/Armenian-National-Trail.eot?x0rd6q');
  src:  url('fonts/Armenian-National-Trail.eot?x0rd6q#iefix') format('embedded-opentype'),
    url('fonts/Armenian-National-Trail.woff2?x0rd6q') format('woff2'),
    url('fonts/Armenian-National-Trail.ttf?x0rd6q') format('truetype'),
    url('fonts/Armenian-National-Trail.woff?x0rd6q') format('woff'),
    url('fonts/Armenian-National-Trail.svg?x0rd6q#Armenian-National-Trail') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="arm-nt-"], [class*=" arm-nt-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Armenian-National-Trail' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.arm-nt-world:before {
  content: "\e92f";
}
.arm-nt-info:before {
  content: "\e930";
}
.arm-nt-location-strocke:before {
  content: "\e931";
}
.arm-nt-cursor:before {
  content: "\e92a";
}
.arm-nt-mail:before {
  content: "\e92b";
}
.arm-nt-phone:before {
  content: "\e92c";
}
.arm-nt-home:before {
  content: "\e92d";
}
.arm-nt-arrow-45:before {
  content: "\e92e";
}
.arm-nt-vector-stroke:before {
  content: "\e90e";
}
.arm-nt-burger_background:before {
  content: "\e91a";
}
.arm-nt-close-background .path1:before {
  content: "\e91b";
  color: rgb(0, 0, 0);
}
.arm-nt-close-background .path2:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.arm-nt-map_full_icon .path1:before {
  content: "\e91d";
  color: rgb(255, 255, 255);
}
.arm-nt-map_full_icon .path2:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.arm-nt-map_icon:before {
  content: "\e91f";
}
.arm-nt-menu-background .path1:before {
  content: "\e920";
  color: rgb(0, 0, 0);
}
.arm-nt-menu-background .path2:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.arm-nt-menu-background .path3:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.arm-nt-menu-background .path4:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.arm-nt-minus-background .path1:before {
  content: "\e924";
  color: rgb(0, 0, 0);
}
.arm-nt-minus-background .path2:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.arm-nt-plus-background .path1:before {
  content: "\e926";
  color: rgb(0, 0, 0);
}
.arm-nt-plus-background .path2:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.arm-nt-settings_is_background:before {
  content: "\e928";
}
.arm-nt-plus:before {
  content: "\e90c";
}
.arm-nt-minus:before {
  content: "\e90d";
}
.arm-nt-play-circle:before {
  content: "\e90a";
}
.arm-nt-pause-circle:before {
  content: "\e90b";
}
.arm-nt-burger-close:before {
  content: "\e918";
}
.arm-nt-menu-open:before {
  content: "\e919";
}
.arm-nt-circle-arrow:before {
  content: "\e900";
}
.arm-nt-map:before {
  content: "\e901";
}
.arm-nt-plate:before {
  content: "\e902";
}
.arm-nt-mount-bottom:before {
  content: "\e903";
}
.arm-nt-mount-top:before {
  content: "\e904";
}
.arm-nt-road:before {
  content: "\e905";
}
.arm-nt-human-mount:before {
  content: "\e906";
}
.arm-nt-facebook-circle:before {
  content: "\e907";
}
.arm-nt-in-circle:before {
  content: "\e908";
}
.arm-nt-circle-clsoe:before {
  content: "\e909";
}
.arm-nt-instagram:before {
  content: "\e90f";
}
.arm-nt-youtube:before {
  content: "\e910";
}
.arm-nt-facebook:before {
  content: "\e911";
}
.arm-nt-semicircular-arrow:before {
  content: "\e912";
}
.arm-nt-left-arrow:before {
  content: "\e913";
}
.arm-nt-right-arrow:before {
  content: "\e914";
}
.arm-nt-dropdown:before {
  content: "\e915";
}
.arm-nt-download:before {
  content: "\e916";
}
.arm-nt-search:before {
  content: "\e917";
}
.arm-nt-settings:before {
  content: "\e929";
}
